<template>
    <div class="user-card">
        <span
                class="user__status"
                v-if="currentStatus"
                :class="currentStatus.class"
        >{{ $t(currentStatus.title) }}</span>
        <span v-else class="user__status applied" :title="selectedPipeline.name">
            {{selectedPipeline.name.substring(0, 9)}}
        </span>
        <div class="user__ratting info-button-light" :class="{'disabled' : !rated }"><i class="eicon e-star"></i>
            <template v-if="rated">{{ applicant.rating }}</template>
        </div>

        <candidate-picture v-if="showAiScore" :applicant="applicant"
                           :picture="applicant.profile_image"></candidate-picture>
        <div v-else>
<!--            <div class="user-picture" style="margin-top: 10px; width: 100px; height: 100px; margin-bottom: 16.8px">-->
            <div class="user-picture">
                <img v-lazy="lazyLoadPicture" alt="applicant-img" class="img-fluid"/>
            </div>
        </div>

        <div class="user-info">
            <h5>{{ applicant.name }}</h5>
            <p>{{ applicant.job_title }}</p>
        </div>

        <router-link class="semi-button semi-button-info mt-auto" v-if="canViewCandidate" :to="{name : 'applicant.details', params: {id :applicant.id}}">
            {{ $t("View Details") }}
        </router-link>
    </div>
</template>
<script>

import Vue from "vue";

const CandidatePicture = () => import("./CandidatePicture");

import {SHOW_CANDIDATE_DETAILS} from '../../constants/events';
import {EventBus} from '../../extra/event-bus';
import client from '../../app/api/Client';
import {mapState} from "vuex";
import {PERMISSIONS} from '../../constants/enums';
import {JOB_APPLY_STATUS} from "../../extra/constants";
import VueLazyLoad from "vue-lazyload";
import {vueJsLazyLoad as vueJsLazyLoadOptions} from "../../config/options";
import {canViewCandidate} from "../../config/permission";

Vue.use(VueLazyLoad, vueJsLazyLoadOptions);


export default {
    components: {CandidatePicture},
    props: ['applicant'],
    data() {
        return {
            jobApplyStatus: [
                {
                    id: '',
                    title: 'All',
                },
                {
                    id: JOB_APPLY_STATUS.COMPLETE,
                    title: 'Applied',
                },
                {
                    id: JOB_APPLY_STATUS.SELECTED,
                    title: 'Selected',
                },
                {
                    id: JOB_APPLY_STATUS.REJECT,
                    title: 'Rejected',
                }
            ]
        }
    },
    computed: {
        ...mapState(['company']),
        selectedPipeline() {
          return this.applicant.job_pipelines.find(p => p.id == this.applicant.pipeline);
        },
        rated() {
            return this.applicant.rating > 0;
        },
        lazyLoadPicture() {
            return {
                src: this.applicant.profile_image,
                error: '/assets/images/placeholder/error.svg',
                loading: '/assets/images/placeholder/circle-loader.svg'
            }
        },
        showAiScore() {
            return this.applicant.showAiScore;
        },

        currentStatus() {
            if (this.applicant.status) {
                let status = _.find(this.jobApplyStatus, {id: this.applicant.status});
                if (status && status.id !== JOB_APPLY_STATUS.COMPLETE) {
                    if (status.id === JOB_APPLY_STATUS.SELECTED) {
                        status.class = 'selected';
                    } else if (status.id === JOB_APPLY_STATUS.REJECT) {
                        status.class = 'rejected';
                    }
                    return status;
                }
            }
            return null;
        },
        canViewCandidate() {
            return canViewCandidate();
        }
    },
    methods: {
        async showCandidateDetails(applicant) {
            if (this.company.permission[PERMISSIONS.CANDIDATE_VIEW]) {
                try {
                    let {data: {data}} = await client().get(`/job/applicant/${applicant.id}`);
                    EventBus.$emit(SHOW_CANDIDATE_DETAILS, data);
                } catch (e) {
                    this.$toast.error(this.$t(e.response.data.message));

                }
            } else {
                this.$toast.error(this.$t('Sorry you do not have permission for this action.'));
            }
        }
    },
}
</script>

<style scoped>
.slide-fade-enter-active {
    transition: all .3s ease;
}

.slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active below version 2.1.8 */
{
    transform: translateX(10px);
    opacity: 0;
}

.page-body .content-area__body .user-card .user__status {
    position: absolute;
    top: 20px;
    left: 20px;
    font-weight: 500;
    font-size: 0.75rem;
    background: #f5f7fd;
    color: #1c2238;
    border-radius: 50px;
    padding: 5px 6px;
    line-height: 1;
}

.page-body .content-area__body .user-card .user__status.selected {
    color: #38b45f;
    background: rgba(96, 206, 131, 0.15);
}

.page-body .content-area__body .user-card .user__status.rejected {
    color: #ff5f74;
    background: rgba(255, 95, 116, 0.15);
}

.page-body .content-area__body .user-card .user__status.applied {
    color: #597dfc;
    background: rgba(89, 125, 252, 0.15);
}
</style>
